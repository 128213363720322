.enable{
    background-color: #991b1b;
    color: #fef2f2;
}

.content {
    height: 50px;
}

.card-footer {
    padding-top: 11px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: flex-end;
}


.service-list{
    margin: 5px 4px;
}

.service-list .service-detail{
  border: 1px solid;
  height: 110px;
}

.service-list .service-detail .card-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.service-list .service-detail .card-body div {
    margin: 0px 10px;
}

.adm-card-header-title{
    width: 100%;
    text-align: center;
}

.adm-card-header{
    margin: auto;
}