.printer .content{
    display: flex;
    flex-direction: column;
}

.printer .content div.detail{
    margin-left: 10px;;
    flex:1
}

.printer .content div.state span{
   vertical-align: middle; 
}

.printer .name{
    font-weight: 800;
    font-size: 1em;
}

.printer .ip {
    font-weight: 400;
    font-size: 0.8em;
}

.printer{
    padding-left: 30px;
}


.adm-list-item.printer{
    height: 140px;
}