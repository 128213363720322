.adm-button{
   /* padding: 1px 5px !important;*/
}

.pad button span{
    font-size: 10px;
    color: white;
    text-transform: capitalize;
}

.pad button{
    min-height: 40px;
}

/*
.pad .sub-category span{
    color: black;
}
    */

.pad .all, .pad .send, .pad .suite{
    width: 100%;
}

.pad .send{
    background-color: #fc2f00;
}

.pad .suite {
    background-color: #4b7191;
}

.pad .boisson span {
    text-transform: capitalize;
    font-weight: bold;
    text-align: center;
    color: white;
}

.pad .plat,
.pad .entree,
.pad .boisson {
}

.pad .plat {
    background-color: #F3722C;
   
    text-transform: capitalize;
    font-weight: bold;
    text-align: center;
    color: white;
}

.pad .entree {
    background-color: #90BE6D;
    text-transform: capitalize;
    
    text-align: center;
    color: white;
}

.pad .boisson {
    background-color: #56758f;
    text-transform: capitalize;
    font-weight: bold;
    text-align: center;
    color: white;
}

.pad .boisson.aperitif {
    background-color: #56758f;
}

.pad .boisson.aperitif-maison {
    background-color: #4b7191;
}





.pad .plate{
    min-height: 80px;
    font-weight: 900;
    font-size: 12px;
}

.categorie{
}

:root{
    --lime-100: #ecfccb;
    --lime-600: #65a30d;
    --lime-900: #365314;

    --rose-100: #ffe4e6;
    --rose-600: #e11d48;
    --rose-900: #881337;

    --amber-100: #fef3c7;
    --amber-600: #d97706;
    --amber-900: #78350f;

    --yellow-100: #fef08a;
    --yellow-600: #ca8a04;
    --yellow-900: #713f12;

    --orange-100: #fed7aa;
    --orange-600: #ea580c;
    --orange-900: #7c2d12;

    --sky-100: #dbeafe;
    --sky-600: #0284c7;
    --sky-900: #0c4a6e;

    --emerald-100: #d1fae5;
    --emerald-600: #059669;
    --emerald-900: #064e3b;

    --purple-100: #f3e8ff;
    --purple-600: #9333ea;
    --purple-900: #581c87;

    --blue-100: #dbeafe;
    --blue-600: #4f46e5;
    --blue-900: #312e81;

    --teal-100: #ccfbf1;
    --teal-600: #0891b2;
    --teal-900: #134e4a;
}

.pad .dessert.coupe {
    background-color: var(--sky-600) !important;
    border: 1px solid var(--sky-900);
    color: var(--sky-100);
    margin: 1px;
}

.pad .dessert.gateau {
    background-color: var(--rose-600) !important;
    border: 1px solid var(--rose-900);
    color: var(--rose-100);
    margin: 1px;
}

.pad .plat.brasserie {
    background-color: var(--blue-600) !important;
    border: 1px solid var(--blue-900);
    color: var(--blue-100);
    margin: 1px;
}

.pad .plat.piece-boucher {
    background-color: var(--purple-600) !important;
    border: 1px solid var(--purple-900);
    color: var(--purple-100);
    margin: 1px;
}

.pad .plat.classique {
    background-color: var(--orange-600) !important;
    border: 1px solid var(--orange-900);
    color: var(--orange-100);
    margin: 1px;
}

.pad .plat.sauce {
    background-color: var(--rose-600) !important;
    border: 1px solid var(--rose-900);
    color: var(--rose-100);
    margin: 1px;
}

.pad .plat.menu-enfant {
    background-color: var(--sky-600) !important;
    border: 1px solid var(--sky-900);
    color: var(--sky-100);
    margin: 1px;
}

.pad .boisson.aperitif {
    background-color: var(--blue-600) !important;
    border: 1px solid var(--blue-900);
    color: var(--blue-100);
    margin: 1px;
}

.pad .boisson.aperitif-maison{
    background-color: var(--purple-600) !important;
    border: 1px solid var(--purple-900);
    color: var(--purple-100);
    margin: 1px;
}

.pad .boisson.boissons-chaudes {
    background-color: var(--emerald-600) !important;
    border: 1px solid var(--emerald-900);
    color: var(--emerald-100);
    margin: 1px;
}

.pad .boisson.digestif {
    background-color: var(--sky-600) !important;
    border: 1px solid var(--sky-900);
    color: var(--sky-100);
    margin: 1px;
}

.biere-pression {
    background-color: var(--orange-600) !important;
    border: 1px solid var(--orange-900);
    color: var(--orange-100);
    margin: 1px;
}

.biere{
    background-color: var(--rose-600) !important;
    border: 1px solid var(--rose-900);
    color: var(--rose-100);
    margin: 1px;
}
.vin {
    background-color: var(--amber-600) !important;
    border: 1px solid var(--amber-900);
    color: var(--amber-100);
    margin: 1px;
}

.soft{
   background-color: var(--lime-600) !important;
   border: 1px solid var(--lime-900);
   color: var(--lime-100);
   margin: 1px;
}

.cocktail {
    background-color: var(--teal-600) !important;
    border: 1px solid var(--teal-900);
    color: var(--teal-100);
    margin: 1px;
}

.adm-button.sub-category{
    height: 50px;
   
   /* width: 60px;*/
}

.adm-button.sub-category span{
    padding: 5px 0px;
}

.pad .adm-button{
    padding: 0;
}