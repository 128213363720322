.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.bottom {
    flex: 0;
    border-top: solid 1px var(--adm-color-border);
}

.top {
    flex: 0;
    border-bottom: solid 1px var(--adm-color-border);
}

.body {
    flex: 1;
}

.plate-commande button{
    width: 100%;
    height: 100%;
}

.plate-commande .sauce.bleu button{
    background-color: #3d348b;
    color: white;
}

.plate-commande .sauce.bleu button.adm-button-fill-outline {
    background-color: white;
    color: #3d348b;
    border-color: #3d348b;
}

.plate-commande .plate {
    display: flex;
    text-align: center;
    height: 40px;
    background-color: #772d2c;
    color: white;
    font-weight: 600;
    font-weight: bold;
    font-size: 16px;
}

.plate-commande .plate>div {
   margin: auto;
}

.plate-commande .caracteristic {
    margin: 5px;
}

.plate-commande .sauce{
    text-transform: capitalize;
}

.plate-commande .sauce.saignant button {
    background-color: #f7b801;
    color: white;
}

.plate-commande .sauce.saignant button.adm-button-fill-outline {
    background-color: white;
    color: #f7b801;
    border-color: #f7b801;
}

.plate-commande .sauce.a-point button {
    background-color: #f18701;
    color: white;
}

.plate-commande .sauce.bien-cuit button {
    background-color: #f35b04;
    color: white;
}

.plate-commande .sauce.bien-cuit button.adm-button-fill-outline {
    background-color: white;
    color: #f35b04;
    border-color: #f35b04;
}

.plate-commande .sauce.a-point button.adm-button-fill-outline {
    background-color: white;
    color: #f18701;
    border-color: #f18701;
}


.plate-commande .accompagnement button{
    background-color: #4d908e;
    color: white;
}

.plate-commande .accompagnement button.adm-button-fill-outline {
    background-color: white;
    color: #4d908e;
    color: #4d908e;
}

.plate-commande .sauce button {
    background-color: #9d4edd;
    color: white;
}

.plate-commande .sauce button.adm-button-fill-outline {
    background-color: white;
    color: #9d4edd; 
    color: #9d4edd;

}

h3{
    text-transform: capitalize;
}

.app.plate-commande .adm-stepper .adm-stepper-input{
    color: black;
    width: 100px;
}

.app.plate-commande .adm-stepper button {
    height: 28px;
}