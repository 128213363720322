 header .title {
    display: flex;
    text-align: center;
    height: 40px;
    background-color: #772d2c;
    color: white;
    font-weight: 600;
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
}

 header .title>div {
    margin: auto;
}

header .back{
 margin: 10px;
}