.category-items span {
    padding-left: 5px;
}

.category-items .adm-list-header{
    position: sticky;
    top: 0px;
    text-transform: capitalize;
    z-index: 2;
    background-color: var(--adm-color-background);
}

.category-items .adm-list-body{
    z-index: 1;
}


.table-flex{
    display: flex;
}


.plate {
    flex: 6 1 0;
    padding-left: 5px;
}

.prize{
    flex: 1 1 0;
    white-space: nowrap;
    margin: auto;
}

.item-DISABLE{
    font-style: italic;
    font-weight: 300;
}

.plate ul.detail{
    display: flex;
    padding-inline-start: 0;
    margin-block-start: 0.3em;
    margin-block-end: 0;
    flex-wrap: wrap;

}

.plate  ul.detail li{
    font-size: 10px;
    padding: 6px 10px;
    list-style: none;
    background-color: #e4e4e7;
    margin-right: 2px;
    margin-top: 2px;
    border-radius: 5px;
    text-transform: capitalize;
}


.star{
    margin: auto;
}