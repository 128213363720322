.service {
    height: 100vh;
    display: flex;
    flex-direction: column;
}


.service .table-info,
.service .table-add
{
    border: 1px solid;
    height: 70px;
}

.service .table-info div,
.service .table-add div
{
    text-align: center;
}

.service section{
    padding: 5px;
}

.bottom {
    flex: 0;
    border-top: solid 1px var(--adm-color-border);
}

.service section{
    flex: 1;
}

.service .table-add div.description{
    padding-top: 5px;
    color: white;
    font-weight: bold;

}

.display-command{
    display: flex;
    flex-direction: row;
  /*  align-items: stretch;*/
   /* border: 1px solid red;*/
    height: 50px;
    width: 100%;
}

.display-command .icon{
    width: 40px;
    margin-top: auto;
    margin-bottom: auto;
}

.display-command .table{
    flex: 2;
}

.display-command div{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
}