.App {
  //text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.plate-selection .adm-grid-item .adm-button{ 
  font-size: 10px;
}

.plate-selection .adm-grid-item button{
  width: 100%;
  height: 70px;
}

.intro{
  display: flex;
}

.intro img{
  margin: auto;
}

.accueil{
  display: flex;
  flex-flow: column;
  height: 100%;
}

.accueil .body{
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 39px;
  background: linear-gradient(#772d2c, #dabfbf);
  flex: 1 1 auto;
  height: 70vh;
}

.adm-card{
 /* height: 60px;*/
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
}



.description{
  font-size: 11px;
  font-style: italic;
  padding-left: 10px;
 
}

.group-item{
  background-color: #efefef;
  margin: 2px;
}

.group-name{
  font-size: 20px;
  padding: 10px;
  font-weight: 900;
}

.group-items{
  padding-left: 10px;
  padding-right: 10px;
}


.header-commande{
  width: 100%;
}

.header-commande div{
  flex: 1;
}

