.boisson  {
    background-color: #577590;
}

.entree{
    background-color: #90BE6D;
}

.boisson .adm-list-item-content{
    background-color: white;
}

.plat {
    background-color: #f9c74f;
}

.plat .adm-list-item-content {
    background-color: white;
}

.boisson .adm-list-item-content-main span,
.plat .adm-list-item-content-main span,
.dessert .adm-list-item-content-main span,
.entree .adm-list-item-content-main span,
.adm-list-item-description{
    padding-left: 5px;
}

div.stepper {
 display: flex;
 align-items: flex-end;
 justify-content: flex-end;
}

div.stepper>* {
    margin-left: 10px;
}

.dessert {
    background-color: #90be6d;
}

.dessert .adm-list-item-content {
    background-color: white;
}

.entree .adm-list-item-content {
    background-color: white;
}


.quantity{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 10px;
}

.commande .adm-list-body {
  /*  height: 400px; */
    overflow-y: scroll;
}

.group-name{
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    top: 0px;
    z-index: 2;
    background: rgb(239, 239, 239);
}

.group-name div{
    flex-grow: 1;
}

.group-name .detail{
    text-align: right;
    font-weight: 400;
    font-style: italic;
}

.group-items‹{
    z-index: 1;
}

.unselectable{
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}